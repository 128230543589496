import SideBar from './component/SideBar'
// import GenerateNewAccount from './component/GenerateNewAccount'

function App() {
  return (
    <div>
      <div className="flex flex-no-wrap">
        <SideBar></SideBar>
      </div>
    </div>
  )
}

export default App
